<template>
  <div>
    <v-card class="rounded-xl header-size">
      <v-fade-transition v-if="!parties.length" leave-absolute>
        <video-background :src="videoCover" class="header-size">
          <div
            class="d-flex flex-column justify-space-between py-8 px-12 rounded-xl header-size"
          >
            <v-row no-gutters>
              <v-col cols="12" sm="7" md="6" lg="5">
                <div
                  class="d-flex flex-column justify-space-between align-center"
                >
                  <div class="d-flex flex-column w-full">
                    <img
                      contain
                      width="100%"
                      alt="TicketMe Logo"
                      style="max-width: 150px"
                      class="unselectable non-draggable"
                      :src="require('@/assets/images/logo/logo-light.png')"
                    />
                    <!-- <h6 class="white--text">
                      Organize eventos de forma simples e rápida
                    </h6> -->
                  </div>
                </div>
              </v-col>
            </v-row>
            <div
              class="d-flex align-center w-full"
              :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
              :style="{ gap: $vuetify.breakpoint.xsOnly ? '' : '0.5rem' }"
            >
              <v-btn
                v-if="!isAuthenticated"
                color="primary"
                to="/auth"
                large
                :block="$vuetify.breakpoint.xsOnly"
              >
                Entrar
              </v-btn>
              <v-btn
                v-else
                color="primary"
                to="/app/ticket"
                :block="$vuetify.breakpoint.xsOnly"
              >
                Meus ingressos
              </v-btn>
              <v-btn
                text
                color="white"
                to="/shop"
                :block="$vuetify.breakpoint.xsOnly"
              >
                Eventos
              </v-btn>
              <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />
              <v-btn
                v-if="!!userPermissions && userPermissions.length"
                text
                color="white"
                to="/admin"
                :block="$vuetify.breakpoint.xsOnly"
              >
                Organizações
              </v-btn>
            </div>
          </div>
        </video-background>
      </v-fade-transition>
      <v-fade-transition v-else hide-on-leave>
        <div class="header-size" style="position: relative">
          <v-carousel
            v-model="partyIndex"
            hide-delimiters
            class="header-size rounded-xl"
            :show-arrows="parties && parties.length > 1"
            interval="10000"
            cycle
          >
            <v-carousel-item
              v-for="(party, i) in parties"
              :key="party.id"
              class="rounded-xl header-size"
            >
              <v-img :src="party.cover" class="h-full rounded-lg header-size">
                <div
                  class="py-6 px-10 rounded-l header-size white--text top-shadow"
                  style="position: absolute; inset: 0"
                >
                  <span
                    class="text-overline lh-1"
                    style="font-size: 10px !important"
                  >
                    {{ party.date | date("DD [de] MMMM") }}

                    <template v-if="party.Address"
                      >• {{ party.Address.city }} - {{ party.Address.state }}
                    </template>
                  </span>
                  <h4 class="mb-0">{{ party.name }}</h4>
                  <!-- <h6>{{ party.Organization.name }}</h6> -->
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-card
            elevation="0"
            outlined
            class="d-flex flex-column justify-end py-6 px-10 rounded-xl header-size transparent"
            style="
              position: absolute;
              max-height: 401px !important;
              inset: 0;
              background: linear-gradient(0deg, black -5%, transparent 30%);
            "
            :to="selectedParty.path"
          >
            <div
              class="d-flex align-end w-full"
              :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
              :style="{ gap: $vuetify.breakpoint.xsOnly ? '' : '0.5rem' }"
            >
              <v-btn
                v-if="!isAuthenticated"
                color="primary"
                class="black--text"
                :to="selectedParty.path"
                large
                :block="$vuetify.breakpoint.xsOnly"
              >
                Comprar
              </v-btn>
              <v-btn
                v-else
                color="primary"
                to="/app/ticket"
                :block="$vuetify.breakpoint.xsOnly"
              >
                Meus ingressos
              </v-btn>
              <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />
              <v-btn
                text
                color="white"
                to="/shop"
                :block="$vuetify.breakpoint.xsOnly"
              >
                Todos os eventos
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-fade-transition>
    </v-card>
  </div>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";
import { videoCover } from "@/themeConfig.js";
import { mapGetters } from "vuex";
import SHOP from "@/services/shop";

export default {
  components: { VideoBackground },
  data() {
    return {
      index: 0,
      parties: [],
      partyIndex: 0,
      videoCover,
    };
  },
  methods: {
    async getParty() {
      const { parties } = await SHOP.highlight();
      this.parties = parties
        .map((party) => ({
          ...party,
          path: `/shop/${party.Organization.slug}/${party.slug || party.id}`,
        }))
        .sort(() => Math.random() - 0.5);
    },
  },
  mounted() {
    this.getParty();
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "userPermissions"]),
    selectedParty() {
      return this.parties[this.partyIndex];
    },
  },
};
</script>

<style>
.header-size {
  height: 60vh !important;
  max-height: 400px !important;
}
.top-shadow {
  background: linear-gradient(175deg, black -5%, transparent 60%) !important;
}
</style>
